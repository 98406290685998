import React from 'react';
import Layout from '../components/layout';

import ContactForm from '../components/index/contactForm/contactForm';

const Contact = () => {
    return ( 
        <Layout>
        <ContactForm/>
        </Layout>

     );
}
 
export default Contact;